<template>
  <div>
    <van-nav-bar class="cart-title" left-arrow fixed @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        已选课程-->开始支付
      </template>
    </van-nav-bar>
    <div class="questionList" style="padding: 6rem 0 22rem 0;">
          <div class="questionList" style="padding-top: 0.5rem;">
                <div class="courseData" >
<!--                  @click="questionDetail"-->

                    <van-cell v-for="(item,index) in list" center :title="item.cate_name" class="answering" >
                      <div slot="label" style="padding-top: 1rem;color: #ff5572;">
                        价格：{{ item.price }}元
                      </div>
                    </van-cell>
                </div>
                <div class="order-price">
                  <van-cell center title="">
                     <template #extra>
                      实际支付：<span class="price">￥{{ price_count }}</span>
                    </template>
                  </van-cell>
                </div>
                <div class="pay-type-select-box">
                  <van-radio-group v-model="pay_type">
                    <van-cell-group>
                      <van-cell class="wechat_pay" center title="微信支付" clickable @click="pay_type = 1">
                        <template #icon>
                          <img style="width: 3rem;height: 3rem;padding-right: .6rem;" height="auto" src="../assets/weixin_pay.png" />
                        </template>
                        <template #right-icon>
                          <van-radio :name='1' />
                        </template>
                      </van-cell>
                      <van-cell class="ali_pay" center title="支付宝支付" clickable @click="pay_type = 2">
                        <template #icon>
                          <img style="width: 3rem;height: 3rem;padding-right: .6rem;"  src="../assets/zhifubao_pay.png" />
                        </template>
                        <template #right-icon>
                          <van-radio :name="2" />
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </van-radio-group>
                </div>
                <div class="order-course-purchase">
                  <van-cell class="course-purchase" title="购课须知">
                    <template #label>
                      <span
                        class="text">如需发票，请在成功交费后登录，点击“发票索取“索要发票，并通过此处查询发票的邮寄状态或者拨打客服电话。</span>
                    </template>
                  </van-cell>
                </div>
          </div>
    </div>
    <div style="position: fixed;bottom: 0;left: 0;right: 0;">
      <van-button type="danger" block size="large" @click="payOrder">确认支付</van-button>
    </div>
  </div>
</template>

<script>

import {getYearCateInfos,payOrder} from '@/request/api'
export default {
  beforeRouteEnter(to, from, next) {
    let ids = to.params.ids;
    console.log(next);
    next();
  },
  name: 'CourseQuestion',
  metaInfo: {
    title: '报名缴费', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
      ids:[],
      list: [],
      result: [],
      pay_type:1,
      price_count:0,
    }
  },
  created() {

  },
  mounted() {
    this.ids = this.$route.query.ids;
    this.getYearCateInfos();
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    getYearCateInfos(){
      getYearCateInfos({ids: this.ids})
          .then(res=>{
            if(res.data.code === 1){
              console.log(res.data.data)
              this.list = res.data.data.data;
              this.price_count = res.data.data.price_count;
            }else{
              this.$toast.fail(res.data.msg);
              history.back();
            }
          })
          .catch(err=>{
            this.$toast.fail('请求错误！');
          })
    },
    payOrder(){
      payOrder({ids:this.ids,pay_type:this.pay_type})
          .then(res=>{
                if(res.data.code === 1){
                  if(this.pay_type === 2){
                    var div= document.createElement("div")
                    div.style.display='none';
                    div.innerHTML = res.data.data;
                    document.body.appendChild(div);
                    document.forms['alipaysubmit'].submit();
                  }else{
                    window.location.href = res.data.data
                  }

                }else if(res.data.code === 2){
                  this.$toast.success(res.data.msg)
                  this.$router.replace({path:"/learning",meta:{activeNum:0,isShowTabbar:true}})
                }else if(res.data.code === -1){
                  this.$toast.fail(res.data.msg);
                  history.back();
                }else{
                  this.$toast.fail(res.data.msg);
                }
          })
          .catch(err=>{})
    },
    onBack() {
      history.back();
      window.android.back();
    },
  },
}
</script>
 
<style lang = "less" >
    .questionList .courseData {
        margin: 0.5rem 1rem 0rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        // padding-bottom: 1.5rem;
    }
    .questionList .courseData .van-checkbox-group .van-checkbox {
        border-top: 0rem solid #e7e9ed !important;
        padding: 1.5rem 1.5rem 0.6rem 1.5rem !important;
    }
    .questionList .courseData .van-cell {
        // border-radius: 1rem !important;
        padding:1.5rem 0.5rem 1.5rem 1.5rem;
        border-bottom: 0.1rem solid #e3e3e3;
    }
    .questionList .courseData .van-cell::after {
        border-width: 0 !important;
    }

    .questionList .courseData .answering .van-cell__title{
        padding-right: 1rem;
        line-height: 3rem;
        font-size: 1.8rem;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;  //使用自适应布局
        // -webkit-line-clamp: 1;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        // -webkit-box-orient: vertical;
    }
    .questionList .courseData .answering .van-icon {
        font-size: 2.4rem;
        padding-right: 0.5rem;
    }
    .questionList .courseData .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;  //使用自适应布局
        -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        -webkit-box-orient: vertical;
    }
    .questionList .courseData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 99999;
      border-top-left-radius: 1rem;
    }
    .questionList .courseData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .questionList .courseData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .questionList .courseData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .icon-img{
      width: 8rem;
    }

    .order-price {
      height: 4rem !important;
      margin: 0 1rem;
      border-top: 0.1rem solid #e7e9ed;
    }

    .order-price .van-cell {
      border-bottom-left-radius: 1.5rem;
      border-bottom-right-radius: 1.5rem;
      padding: 0 1.5rem;
      font-size: 2rem;
    }

    .order-price .van-cell .price {
      padding: 0rem;
      font-size: 2.4rem;
      height: 3rem;
      line-height: 3rem;
      font-weight: 600;
      color: #ee0a24;
    }
    .order-course-purchase {
      height: 4rem !important;
      padding: 0.5rem 1rem;
    }

    .order-course-purchase .course-purchase {
      border-radius: 1rem;
      padding: 1.5rem;
      font-size: 2rem;
    }

    .order-course-purchase .course-purchase .van-cell__label {
      font-size: 1.8rem;
      line-height: 3rem;
      padding-top: 0.5rem;
    }

    .order-course-purchase .course-purchase .van-icon {
      font-size: 2.4rem;
    }
    .pay-type-select-box .van-cell{
      padding: 1.5rem !important;
    }
    .pay-type-select-box .van-cell__title{
      align-content: center;
    }
    .pay-type-select-box{
      padding: 6rem 1rem 0.5rem;
    }
    .pay-type-select-box .wechat_pay {
      border-top-left-radius: 1.5rem !important;
      border-top-right-radius: 1.5rem !important;
    }
    .pay-type-select-box .ali_pay {
      border-bottom-left-radius: 1.5rem !important;
      border-bottom-right-radius: 1.5rem !important;
    }
    .pay-type-select-box .van-cell-group{
      border-radius: 1.5rem ;
    }

</style>