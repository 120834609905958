<template>
    <div class="container">
        <van-nav-bar class="learning-title" fixed left-arrow>
            <template #left>
                <van-button color="#ffffff" icon="arrow-down" @click="onBack">在线考试</van-button>
            </template>
            <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="客服"/>
                </van-grid>
            </template>
        </van-nav-bar>


        <div v-if="testPaperList.length>0">
          <div class="courseCell" v-for="(item,index) in testPaperList" :key="index">
            <van-cell center is-link @click="goExam(item.id,item.oid,item.order_id,item.title,item.is_pass)">
              <!-- <template #icon>
                  <van-icon name="circle"/>
              </template> -->
              <template #right-icon>
                <van-icon name="chart-trending-o" v-if="item.is_pass === 1"/>
                <!-- 下面是已做试题按钮 -->
                <van-icon name="edit" v-else  />
              </template>
              <template #title>
                <div class="van-cell__title" v-html="item.title">

                </div>
              </template>
              <template #label>
                <div class="van-cell__label">
                  <van-tag type="success" v-if="item.is_pass === 1">考试已通过</van-tag>
                  <van-tag type="danger" v-else-if="item.is_pass === 2">考试未通过</van-tag>
                  <van-tag type="primary" v-else>点击开始考试</van-tag>
                </div>
              </template>
            </van-cell>
          </div>
        </div>
        <!-- 无课程的时候显示 -->
        <van-empty
                v-else
                description="当期课程暂未更新配套习题"
                image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                image-size="24rem">
        </van-empty>
    </div>
</template>

<script>
    import {getPaperList} from "@/request/api";
    import {Toast} from "vant";
    let formName;
    let paramsData;
    // let commodityId;
    export default {
        beforeRouteEnter(to, from, next) {
            let that = this;
            formName = from.name;
            paramsData = from.params;
            next();
        },
        metaInfo() {
            return {
                title: this.title + '-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                active: '',
                item: '',
                courseData: [],
                courseFreeData: [],
                lastStudy: [],
                courseShow: true,
                testPaperList: [],
                commodity_id: '',
                course_id: '',
                cate_id: '',
                coursewareId: ''
            }
        },
        methods: {
            getTestPaperList() {
                let that = this;
                let id = that.commodity_id;
                let course_id = that.course_id;
                let cate_id = that.cate_id;
              getPaperList({id,cate_id,course_id})
                    .then(res => {
                        if (res.data.code == 1) {
                            that.testPaperList = res.data.data;
                            console.log(that.testPaperList)
                        } else {
                            Toast.fail(res.data.msg);
                        }
                    })
                    .catch(err => {
                        console.log("error");
                        console.log(err);
                    })
            },
            onBack() {
                
                history.back();
                window.android.back();
            },
            goExam(id,oid,order_id,title,is_pass) {
              if(is_pass === 1){
                this.$toast.fail('考试已通过')
                return false;
              }
                this.$router.push({
                    name: 'zxksDT',
                    query: {
                        title,
                        id,
                        oid,
                        order_id
                    },
                    params: {
                        title,
                        id,
                        oid,
                        order_id
                    }
                })
            }
        },
        created() {
        },
        mounted() {
          this.getTestPaperList();
        }
    }
</script>

<style lang="less">
    .container{
        padding: 6rem 0 8rem 0;
    }
    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-right: 2rem;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.4rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }

    .courseList {
        padding: 6rem 0 8rem 0;
    }

    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 0;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }

    .courseList .van-sticky {
        padding-top: 6rem;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }

    .courseCell {
        margin: 1rem;
        border-radius: 1rem;
        padding: 1rem;
        background-color: #ffffff;
    }

    .courseCell .van-tag {
        padding: 0.4rem 1rem 0.4rem 0.5rem !important;
        font-size: 1.6rem !important;
    }

    .courseCell .van-tag font {
        color: #1989fa;
        font-size: 1.6rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.3rem;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .courseCell .van-cell {
    //     padding: 1rem 0;
    // }

    .courseCell .van-cell .van-icon {
        margin-right: 0.8rem;
        font-size: 1.8rem;
        color: #646566;
    }

    .courseCell .van-cell .van-icon-edit {
        margin-right: 0rem;
        font-size: 3.2rem;
        color: #646566;
    }

    .courseCell .van-cell .van-icon-chart-trending-o {
        margin-right: 0rem;
        font-size: 3.2rem;
        color: #1989fa;
    }
    .courseCell .van-cell .van-cell__title {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .courseCell .van-cell .van-cell__label {
        font-size: 1.6rem;
        line-height: 3rem;
        padding-top: 0.5rem;
    }

    .lastCell {
        padding: 1rem 1.5rem;
        background-color: #1989fa;
        position: fixed;
        bottom: 0;
    }

    .lastCell .van-icon {
        font-size: 6rem;
        margin-right: 1rem;
    }

    .lastCell .van-cell__title {
        color: #ffffff;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lastCell .van-cell__label {
        line-height: 3rem;
    }

    .lastCell .van-cell__label .van-tag {
        font-size: 1.6rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.3rem;
    }

</style>
