<template>
  <div>
    <van-nav-bar class="cart-title" left-arrow fixed @click-left="onBack" @click-right="isDelete = !isDelete">
      <template #title>
        报名缴费
      </template>
    </van-nav-bar>
    <div class="questionList" style="padding: 6rem 0 22rem 0;" v-if="list.length > 0">
          <div class="questionList" style="padding-top: 0.5rem;">
                <div class="courseData" wx>
                  <van-checkbox-group v-model="course_ids">
                    <van-cell-group >
                      <van-cell
                        class="answering"
                        v-for="(item, index) in list"
                        clickable
                        :key="item.id"
                        @click="toggle(index)"
                      >
                        <template #title>
                          <div style="height: 6rem;">
                            {{ item.cate_name }}
                          </div>
                        </template>
                        <template #label>
                          <div style="padding-top: 1rem;color: #ff5572;">
                            价格：{{item.price}}元
                          </div>
                        </template>
                        <template #right-icon>
                          <van-checkbox :name="item.id"   ref="checkboxes" />
                        </template>
                      </van-cell>
                    </van-cell-group>
                  </van-checkbox-group>

                </div>

          </div>
    </div>
    <div style="position: fixed;bottom: 0;left: 0;right: 0;"  v-if="list.length > 0">
      <van-button type="info" block size="large" @click="onClickNext">确认选择，下一步</van-button>
    </div>
    <van-empty  v-if="list.length <= 0" image-size="30rem" description="暂无课程"/>

  </div>
</template>

<script>

// import Tips from '@/components/Tips'
import {getYearCateList} from '@/request/api'
export default {
  name: 'CourseQuestion',
  metaInfo: {
    title: '报名缴费', //设置页面title
    meta: [{                 //设置页面meta
      name: 'keyWords',
      content: '关键词'
    },
    {
      name: "description",
      content: "描述",
    },
    ]
  },
  data() {
    return {
      // active:'',
      list: [
      ],
      result: [],
      course_ids:[],
    }
  },
  created() {
  },
  mounted() {
    this.getList();
  },
  methods: {
    toggle(index) {
      this.$refs.checkboxes[index].toggle();
    },
    getList(){
      getYearCateList()
          .then(res=>{
            console.log(res.data);
            if(res.data.code === 1){
              this.list = res.data.data;
            }
          })
          .catch(err=>{
            this.$toast.fail('请求错误！')
          })
    },
    onBack() {
      history.back();
      window.android.back();
    },
    onClickNext() {
      let ids = this.course_ids;
      this.$router.push({name:'courseSelectPayment',params:{ids},query:{ids}})
    },
  },
}
</script>
 
<style lang = "less" >
    .questionList .courseData {
        margin: 0.5rem 1rem 1rem 1rem;
        border-radius: 1rem;
        background-color: #ffffff;
        // padding-bottom: 1.5rem;
    }
    .questionList .courseData .van-checkbox-group .van-checkbox {
        border-top: 0rem solid #e7e9ed !important;
        padding: 1.5rem 1.5rem 0.6rem 1.5rem !important;
    }
    .questionList .courseData .van-cell {
        // border-radius: 1rem !important;
        padding:1.5rem 0.5rem 1.5rem 1.5rem;
        border-bottom: 0.1rem solid #e3e3e3;
    }
    .questionList .courseData .van-cell::after {
        border-width: 0 !important;
    }

    .questionList .courseData .answering .van-cell__title{
        padding-right: 1rem;
        line-height: 3rem;
        font-size: 1.8rem;
        // overflow: hidden;
        // text-overflow: ellipsis;
        // display: -webkit-box;  //使用自适应布局
        // -webkit-line-clamp: 1;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        // -webkit-box-orient: vertical;
    }
    .questionList .courseData .answering .van-icon {
        font-size: 2.4rem;
        padding-right: 0.5rem;
    }
    .questionList .courseData .answering .van-cell__label {
        color: #323233;
        font-size: 1.8rem;
        line-height: 2.6rem;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;  //使用自适应布局
        -webkit-line-clamp: 2;  //设置超出行数，要设置超出几行显示省略号就把这里改成几
        -webkit-box-orient: vertical;
    }
    .questionList .courseData .project_tag{
      position: absolute;
      left: 1rem;
      // float: left;
      padding: 0.3rem 0.8rem !important;
      font-size: 1.5rem !important;
      z-index: 99999;
      border-top-left-radius: 1rem;
    }
    .questionList .courseData .reply_tag{
      background-color: #f5f5f5;
      border-radius: 3rem;
      // padding-left: 0.3rem;
      height: 3.2rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      // width: 90%;
      margin: 0 1rem;
    }
    .questionList .courseData .reply_tag .van-tag{
      height: 2.8rem !important;
      // width: 3rem !important;
      border-radius: 3rem;
      padding: 0.1rem 1rem !important;
      font-size: 1.8rem !important;
    }
    .questionList .courseData .reply_tag .reply_text{
      padding-left: 0.2rem;
      font-size: 1.8rem;
      color: #646566;
    }
    .icon-img{
      width: 8rem;

    }

</style>