<template>
    <div class="container">
        <van-nav-bar class="learning-title" fixed left-arrow>
            <template #left>
                <van-button color="#ffffff" icon="arrow-down" @click="onBack">查看证书</van-button>
            </template>
            <template #right>
                <van-grid>
                    <van-grid-item icon="chat-o" text="客服"/>
                </van-grid>
            </template>
        </van-nav-bar>
        <div class="courseList" v-if="list.length > 0">
            <div class="courseCell" v-for="(item, index) in list">
                <van-cell center @click="catViewCard(item.id,item.status)">
                    <template #title>
                        <div class="van-cell__title">
                          {{ item.cate_name }}
                        </div>
                    </template>
                    <template #label>
                        <div class="van-cell__label">
                            <van-tag type="success" v-if="item.status === 1">查看证书</van-tag>
                            <van-tag  color="#C0C0C0" v-else> 查看证书</van-tag>
                        </div>
                    </template>
                </van-cell>
            </div>
        </div>

        <!-- 无证书的时候显示 -->
         <van-empty
                v-else
                description="当前暂无证书"
                image="https://cdnoss.ksyt.com.cn/wxImages/no-course.png"
                image-size="24rem">
        </van-empty>
    </div>
</template>

<script>
    const API_HOST = process.env.VUE_APP_BASE_API;
    import {getUserOrderList} from "@/request/api";
    import {Toast} from "vant";
    // let commodityId;
    export default {
        metaInfo() {
            return {
                title: this.title + '-学习', //设置页面title
            }
        },
        data() {
            return {
                title: this.getBranchCompany(),
                active: '',
                item: '',
                list:[],
            }
        },
        methods: {
            onBack() {
                
                history.back();
                window.android.back();
            },
            getUserOrderList(){
              getUserOrderList()
                  .then(res => {
                    if(res.data.code === 1){
                      this.list = res.data.data
                    }
                  })
                  .catch(err => {})
            },
            catViewCard(order_id,status){
              if(status !== 1){
                this.$toast.fail('未完成考试无法查看')
                return false;
              }
              let token = localStorage.getItem("token")
              window.location.href = API_HOST+'/index/zhengshu?orderId='+order_id+"&token="+token
            }
        },
        created() {
            this.commodity_id = this.$route.query.id
            this.course_id = this.$route.query.course_id
            this.cate_id = this.$route.query.cate_id
            this.coursewareId = this.$route.query.coursewareId
        },
        mounted() {
          this.getUserOrderList();

        },
    }
</script>

<style lang="less">
    .learning-title .van-nav-bar__content .van-nav-bar__left .van-button__text {
        font-size: 2.2rem !important;
        font-weight: 400 !important;
        color: #333333 !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__left .van-icon {
        font-size: 2.2rem !important;
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        margin-right: 2rem;
        font-weight: 600;
        color: #333333;
        -moz-transform: rotate(90deg);
        -webkit-transform: rotate(90deg);
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-icon {
        font-size: 2.8rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__text {
        font-size: 1.4rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right {
        padding: 0 0.5rem !important;
    }

    .learning-title .van-nav-bar__content .van-nav-bar__right .van-grid-item__content {
        width: 6rem !important;
    }
    .courseList .van-tabs__line {
        bottom: 0.5rem;
        left: 0;
        z-index: 0;
        width: 3.2rem;
        height: 0.5rem;
        border-radius: 2rem;
        position: absolute;
    }

    .courseList .van-sticky {
        padding-top: 6rem;
    }

    .courseList .van-tab--active .van-tab__text {
        font-size: 2rem;
    }

    .courseCell {
        margin: 1rem;
        border-radius: 1rem;
        padding: 1rem;
        background-color: #ffffff;
    }

    .courseCell .van-tag {
        padding: 0.4rem 1rem 0.4rem 0.5rem !important;
        font-size: 1.6rem !important;
    }

    .courseCell .van-tag font {
        color: #1989fa;
        font-size: 1.6rem;
        width: 2rem;
        height: 2rem;
        margin-right: 0.3rem;
        background-color: #ffffff;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    // .courseCell .van-cell {
    //     padding: 1rem 0;
    // }

    .courseCell .van-cell .van-icon {
        margin-right: 0.8rem;
        font-size: 1.8rem;
        color: #646566;
    }

    .courseCell .van-cell .van-icon-edit {
        margin-right: 0rem;
        font-size: 3.2rem;
        color: #646566;
    }

    .courseCell .van-cell .van-icon-chart-trending-o {
        margin-right: 0rem;
        font-size: 3.2rem;
        color: #1989fa;
    }
    .courseCell .van-cell .van-cell__title {
        font-size: 1.8rem;
        line-height: 3rem;
    }

    .courseCell .van-cell .van-cell__label {
        font-size: 1.6rem;
        line-height: 3rem;
        padding-top: 0.5rem;
    }

    .lastCell {
        padding: 1rem 1.5rem;
        background-color: #1989fa;
        position: fixed;
        bottom: 0;
    }

    .lastCell .van-icon {
        font-size: 6rem;
        margin-right: 1rem;
    }

    .lastCell .van-cell__title {
        color: #ffffff;
        line-height: 3rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .lastCell .van-cell__label {
        line-height: 3rem;
    }

    .lastCell .van-cell__label .van-tag {
        font-size: 1.6rem !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        border-radius: 0.3rem;
    }
    .container{
      padding: 6rem 0 8rem 0;
    }
</style>
